import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  CardActionArea,
  CardActions,
  CardMedia,
  Button
} from '@material-ui/core';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  titleBar: {
    backgroundColor: theme.palette.secondary.main
  },
  titleBarText: {
    color: theme.palette.cardText.primary
  }
}));

const ProfileCard = ({ className, profile, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardActionArea>
        <CardMedia
          className={clsx(classes.media, className)}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Grid className={classes.titleBar} container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar>W</Avatar>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="h5" className={clsx(classes.titleBarText, className)} component="h2">
                {profile.firstname} {profile.lastname}
              </Typography>
              <Typography gutterBottom variant="h6" className={clsx(classes.titleBarText, className)} component="h2">
                {profile.city}
              </Typography>
            </Grid>
          </Grid>
          <Box container="div" mt={1} mb={1}>
            <Typography variant="body2" color="textSecondary" component="p">
              {profile.introduction}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Connect
        </Button>
        <Button size="small" color="primary">
          Shortlist
        </Button>
      </CardActions>
    </Card>
  );
};

ProfileCard.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default ProfileCard;