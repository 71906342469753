import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import axios from 'axios';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';

import * as serviceWorker from './serviceWorker';
import App from './App';
import reducer from './store/reducers/reducer';
import authReducer from './store/reducers/auth';
import { loadState, saveState } from './localStorage';
// import axiosServer from './axios-api-server';

// axios.defaults.baseURL = 'baseURL';
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

// axios.interceptors.request.use(async request => {
//   console.log(request);
//   // TODO: Necessary editing of request here
//   return request;
// }, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(response => {
//   console.log(response);
//   return response;
// }, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

const rootReducer = combineReducers({
  redr: reducer,
  auth: authReducer
});

const logger = store => {
  return next => {
    return action => {
      console.log('middleware', action);
      const result = next(action);
      console.log(result);
      return result;
    }
  }
}

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, /* preloadedState, */ persistedState ,composeEnhancers(
  applyMiddleware(logger, thunk)
));

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
