import * as actionTypes from './actionTypes';
import * as fbAuth from '../../firebase';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (idToken, refreshToken, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: idToken,
    refreshToken: refreshToken,
    userId: userId
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logoutSuccess = () => {
  console.log('logout successfull')
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const logout = () => {
  return dispatch => {
    console.log('attempting logout')
    fbAuth.auth.signOut()
    .then(result => dispatch(logoutSuccess()))
    .catch(error => console.log(error))
  }
};

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  }
}

export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    fbAuth.auth.signInWithEmailAndPassword(email, password)
      .then(async(response) => {
        const idToken = await response.user.getIdToken();
        const tokenResult = await response.user.getIdTokenResult();
        dispatch(authSuccess(idToken, response.user.refreshToken, response.user.uid));

        // Setting up expiration timer
        var startDateTime = new Date();
        var endDateTime = new Date(tokenResult.expirationTime);
        var expiresInMilliseconds = (endDateTime.getTime() - startDateTime.getTime());
        dispatch(checkAuthTimeout(expiresInMilliseconds));
      })
      .catch(error => {
        console.log(error);
        dispatch(authFail(error));
      });
  };
};
