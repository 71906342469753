import axios from 'axios';
import firebase from 'firebase';

const instance = axios.create({
  baseURL: 'http://localhost:7777/api/',
  json: true
});

// TODO: manage without localStorage later
instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('idToken');

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + idToken;
    localStorage.setItem('idToken', idToken);
  }
})

instance.interceptors.request.use(async request => {
  const currentUser = await firebase.auth().currentUser;
  let idToken;
  if (currentUser) {
    idToken = await firebase.auth().currentUser.getIdToken();
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + idToken;
  }
  return request;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

instance.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 403) {
    // TODO: Redirect to login here
    return Promise.reject(error);
  }
  return Promise.reject(error);
});


// axios.interceptors.response.use(response => {
//   console.log(response);
//   return response;
// }, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

export default instance;
