import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA8LqJm7odVSKSfHdx7E-9Hwhu5CofPouc",
  authDomain: "sewa-dev.firebaseapp.com",
  databaseURL: "https://sewa-dev.firebaseio.com",
  projectId: "sewa-dev",
  storageBucket: "sewa-dev.appspot.com",
  messagingSenderId: "728134595898",
  appId: "1:728134595898:web:59258af506cd0c11b5cc03",
  measurementId: "G-W2RCKTFZVD"
};

firebase.initializeApp(firebaseConfig);

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleAuthProvider);
};
// TODO: Not working facebook login
export const signInWithFacebook = () => {
  auth.signInWithPopup(facebookAuthProvider);
}
