import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import ProfileCard from './ProfileCard';
// import data from './data';
import axiosServer from '../../../axios-api-server';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  // const [products] = useState(data);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const queryParams = {
      page: currentPage,
    }
    getProfiles(queryParams);
  }, []);

  const getProfiles = (queryParams) => {
    axiosServer
      .get('/profiles', { params: queryParams })
      .then(response => {
        processListData(response.data);
        // setProfiles(response.data.profiles || []);
      })
      .catch(error => console.log(error));
  }

  const processListData = (data) => {
    const { totalPages, currentPage, itemlist } = data;
    setProfiles(itemlist || []);
    setCurrentPage(currentPage + 1);
    setTotalPages(totalPages);
  }

  const handlePageChange = (event, value) => {
    const queryParams = {
      page: value - 1,
    }
    getProfiles(queryParams);
  }

  return (
    <Page
      className={classes.root}
      title="Profiles"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {profiles.map((profile) => (
              <Grid
                item
                key={profile.id}
                lg={4}
                md={6}
                xs={12}
              >
                <ProfileCard
                  className={classes.profileCard}
                  profile={profile}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            size="small"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
